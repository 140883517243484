import React, { createContext, useContext, useState } from "react";

interface LocationContextType {
  locationName: string | null;
  setLocationName: React.Dispatch<React.SetStateAction<string | null>>;
}

const LocationContext = createContext<LocationContextType | undefined>(
  undefined
);

export const LocationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [locationName, setLocationName] = useState<string | null>(null);

  return (
    <LocationContext.Provider value={{ locationName, setLocationName }}>
      {children}
    </LocationContext.Provider>
  );
};

export const useLocation = () => {
  const context = useContext(LocationContext);
  if (!context) {
    throw new Error("useLocation must be used within a LocationProvider");
  }
  return context;
};
