import React, { createContext, useState, useContext } from "react";

interface RiskDataContextProps {
  topThreeIndicis: string[];
  setTopThreeIndiciss: React.Dispatch<React.SetStateAction<string[]>>;
  topThreeRisks: any[];
  setTopThreeRiskss: React.Dispatch<React.SetStateAction<any[]>>;
  profitData: any;
  setProfitDatas: React.Dispatch<React.SetStateAction<any>>;
}

// Create the context
export const RiskDataContext = createContext<RiskDataContextProps | undefined>(
  undefined
);

// Provider component
export const LivestockRiskDataProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [topThreeIndicis, setTopThreeIndiciss] = useState<string[]>([]);
  const [topThreeRisks, setTopThreeRiskss] = useState<any[]>([]);
  const [profitData, setProfitDatas] = useState<any>(null);

  return (
    <RiskDataContext.Provider
      value={{
        topThreeIndicis,
        setTopThreeIndiciss,
        topThreeRisks,
        setTopThreeRiskss,
        profitData,
        setProfitDatas,
      }}
    >
      {children}
    </RiskDataContext.Provider>
  );
};

// Custom hook for accessing context
export const useRiskDatas = () => {
  const context = useContext(RiskDataContext);
  if (!context) {
    throw new Error("useRiskData must be used within a RiskDataProvider");
  }
  return context;
};
