import { useEffect, useState, CSSProperties, useRef } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/home/home";
import Engagement from "./pages/engagement";
import TeamMembers from "./pages/teams";
import Roles from "./pages/teams/roles";
import Companies from "./pages/companies";
import RecentActivity from "./pages/Activity";
import LogIn from "./pages/login";
import Resources from "./pages/resources";
import OtpPage from "./pages/login/otp";
import Profile from "./pages/profile";
import HelpPage from "./pages/help";
import AdminHome from "./pages/home/adminhome";
import CreatePassword from "./pages/login/createPassword";
import UsersList from "./pages/users";
import { UserProvider } from "./context/UserProvider";
import UnauthorizedPage from "./pages/unauthorized";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Scores from "./pages/resources/scores";
import EcoCrop from "./pages/Eco";
import { axiosPrivateOid } from "./api/axiosPrivate";
import BeatLoader from "react-spinners/BeatLoader";
import CompanyActivity from "./pages/CompanyActivity";
import UserSearches from "./pages/UserSearches";
import { msalInstance } from "./authConfig";
import Swal from "sweetalert2";
import { clarity } from "react-microsoft-clarity";
import LandingDash from "./pages/landingDash";
import Livestock from "./pages/liveStock";
import LivestockResources from "./pages/resources/livestockResources";
import LiveStockScores from "./pages/resources/livestockScores";
import UserDashboard from "./pages/UserDashboard";
import PaymentBillingHistory from "./pages/PaymentBillingHistory";
import CurrencyDetailsPage from "./pages/Currency";
import { usePayment } from "./context/Payment";
import CheckoutPage from "./pages/CheckoutPage";
import CheckoutSuccess from "./pages/CheckoutSuccess";
import ResourceItem from "./pages/ResourceItem";
import LivestockListItem from "./pages/livestockListItem";
import { useLanguage } from "./context/Language";
import { LocationProvider } from "./context/Location";
import { RiskDataProvider } from "./context/RIskData";
import { LivestockRiskDataProvider } from "./context/LivestockRiskData";
// import CurrencyDetailsPage from "./pages/Currency";
// import { API_BASE_URL_NEW } from "./constants";
// import axios from "./api/axios";
// import { loginRequest } from "./authConfig";
// import { jwtDecode } from "jwt-decode";
// import { msalInstance } from "./main";
// import { EventType } from "@azure/msal-browser";

const override: CSSProperties = {
  display: "flex",
  margin: "0 auto",
  borderColor: "red",
  textAlign: "center",
  justifyContent: "center",
  top: "50%",
  position: "absolute",
  left: "50%",
  transform: "translate(-50%,-50%)",
};
const queryClient = new QueryClient();

function App() {
  const [loading, setLoading] = useState(true);

  //@ts-ignore
  const { setSelectedLanguage } = useLanguage();
  //@ts-ignore
  const { setCredits } = usePayment();

  setSelectedLanguage(localStorage.getItem("lang") || "en");
  clarity.init("l1t2h06gck");

  async function roleData() {
    axiosPrivateOid<any>({ method: "GET", url: "/me" })
      .then((data) => {
        console.log(data, "data");
        localStorage.setItem("empType", data?.data?.employeeType);
        localStorage.setItem("displayName", data?.data?.displayName);
        localStorage.setItem("mail", data?.data?.mail);
        localStorage.setItem("oid", data?.data?.id);
        setLoading(false);

        if (data.data.employeeType === "single_user") {
          setCredits(
            Math.floor(data.data.user_searches_quota) -
              Math.floor(data.data.user_searches_used)
          );
        }
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  const timeoutRef = useRef(null);
  const activityEvents = [
    "mousedown",
    "mousemove",
    "keydown",
    "scroll",
    "touchstart",
  ];

  const resetSession = () => {
    if (timeoutRef.current) {
      // console.log(`Countdown cancelled due to ${event.type} event.`);
      // clearTimeout(timeoutRef.current);
      clearInterval(timeoutRef.current);
    }
    // console.log("Starting countdown...");

    let countdownTime = 60 * 30; // 30 minutes in seconds
    timeoutRef.current = setInterval(() => {
      // let minutes = Math.floor(countdownTime / 60);
      let seconds = countdownTime % 60;

      // Pad the minutes and seconds with leading zeros if needed
      if (seconds % 10 == 0) {
        // only show the countdown for every 10 seconds
        // let formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
        //   .toString()
        //   .padStart(2, "0")}`;
        // console.log(`Auto-Logout Countdown: ${formattedTime}`);
      } else if (seconds < 10) {
        // show the countdown in the last 10 seconds
        // let formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
        //   .toString()
        //   .padStart(2, "0")}`;
        // console.log(`Auto-Logout Countdown: ${formattedTime}`);
      }
      countdownTime--;
      if (countdownTime > 59 && countdownTime === 60) {
        Swal.fire({
          title: "Auto-Logout Warning",
          text: "You will be logged out in 1 minute.",
          icon: "warning",
          showConfirmButton: true,
        });
      }
      // If the countdown time reaches zero, clear the interval and log out
      if (countdownTime < 0) {
        clearInterval(timeoutRef.current);
        // console.log("Countdown finished. Logging out...");
        msalInstance.logoutRedirect();
      }
    }, 1000);
  };

  useEffect(() => {
    // @ts-ignore
    import("preline");

    roleData();

    resetSession();

    // Reset the timeout whenever there's user activity
    activityEvents.forEach((event) => {
      window.addEventListener(event, resetSession, { passive: true });
    });

    // Clear the timeout when the component is unmounted
    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      activityEvents.forEach((event) => {
        window.removeEventListener(event, resetSession);
      });
    };
  }, []);

  return (
    <>
      {loading ? (
        <BeatLoader
          color={"#87CEEB"}
          // loading={loading}
          cssOverride={override}
          size={70}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <QueryClientProvider client={queryClient}>
          <LocationProvider>
            <LivestockRiskDataProvider>
              <RiskDataProvider>
                <UserProvider>
                  <Routes>
                    {/* public routes */}

                    <Route path="/login" element={<LogIn />} />
                    <Route path="/verify" element={<OtpPage />} />
                    <Route path="/create_password" element={<CreatePassword />} />
                    <Route path="/unauthorized" element={<UnauthorizedPage />} />

                    {/* <UserProvider> */}
                    <Route path="/dashboard" element={<Home />} />
                    <Route path="/livestock" element={<Livestock />} />
                    <Route path="/help" element={<HelpPage />} />
                    {/* <Route path="/team" element={<TeamMembers />} /> */}
                    <Route path="/landing" element={<LandingDash />} />
                    <Route path="/currency" element={<CurrencyDetailsPage />} />
                    <Route path="/teams/:companyId" element={<TeamMembers />} />
                    <Route path="/user-dashboard" element={<UserDashboard />} />
                    <Route path="/checkout" element={<CheckoutPage />} />
                    <Route
                      path="/resource-item/:name"
                      element={<ResourceItem />}
                    />
                    <Route
                      path="/payment-history"
                      element={<PaymentBillingHistory />}
                    />
                    <Route
                      path="/teams/activity/:companyId"
                      element={<CompanyActivity />}
                    />
                    <Route
                      path="/teams/activity/searches/:userId"
                      element={<UserSearches />}
                    />
                    <Route path="/success-topup" element={<CheckoutSuccess />} />
                    <Route path="/ecocrop" element={<EcoCrop />} />
                    <Route path="/users" element={<UsersList />} />
                    <Route path="/resources" element={<Resources />} />
                    <Route path="/scores" element={<Scores />} />
                    <Route
                      path="/livestockResources"
                      element={<LivestockResources />}
                    />
                    <Route
                      path="/livestockResources-item/:name"
                      element={<LivestockListItem />}
                    />
                    <Route
                      path="/livestockScores"
                      element={<LiveStockScores />}
                    />
                    <Route path="/engagement" element={<Engagement />} />
                    <Route path="/profile" element={<Profile />} />
                    {/* we want to protect these routes */}
                    {/* <Route element={<Auth allowedRoles={["admin"]} />}> */}
                    {/* <Route path="/" element={<LogIn />} /> */}
                    <Route path="/roles" element={<Roles />} />
                    <Route path="/companies" element={<Companies />} />
                    <Route path="/recent_activity" element={<RecentActivity />} />
                    <Route path="/admin" element={<AdminHome />} />
                    {/* </Route> */}
                    {/* </UserProvider> */}
                  </Routes>
                </UserProvider>
              </RiskDataProvider>
            </LivestockRiskDataProvider>
          </LocationProvider>
        </QueryClientProvider>
      )}
    </>
  );
}

export default App;
