import React, { createContext, useState, useContext } from "react";
import { TotalScores, LocationScoreId } from "../data/riskData";


interface RiskData {
  total_scores: TotalScores;
  location_score_id: LocationScoreId;
}

interface RiskDataContextProps {
  riskData: RiskData | null;
  setRiskDatas: React.Dispatch<React.SetStateAction<RiskData | null>>;
}

const RiskDataContext = createContext<RiskDataContextProps | undefined>(
  undefined
);

export const RiskDataProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [riskData, setRiskDatas] = useState<RiskData | null>(null);
  return (
    <RiskDataContext.Provider value={{ riskData, setRiskDatas }}>
      {children}
    </RiskDataContext.Provider>
  );
};

export const useRiskData = () => {
  const context = useContext(RiskDataContext);
  if (!context) {
    throw new Error("useRiskData must be used within a RiskDataProvider");
  }
  return context;
};
