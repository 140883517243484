//@ts-nocheck
import { useState, useEffect } from "react";
import ChatLogItem from "./chat/ChatLogItem";
import TypingAnimation from "./chat/TypingAnimation";
import { ChatCompletion } from "../data/chatData";
import toast from "react-hot-toast";
import { axiosPrivateOid } from "../api/axiosPrivate";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useRiskData } from "../context/RIskData"; // Import your custom hook
import { useRiskDatas } from "../context/LivestockRiskData";

interface ChatMessage {
  type: "user" | "bot";
  message: string;
}


const ChatBot = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [chatLog, setChatLog] = useState<ChatMessage[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const { t } = useTranslation("score");
  const { riskData } = useRiskData();
  const { topThreeIndicis, topThreeRisks, profitData } = useRiskDatas();
  console.log(profitData)
  const toggleChat = () => {
    setIsChatOpen((prev) => !prev);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (inputValue.trim() === "") {
      toast.error("Please enter a message");
      return;
    }

    setChatLog((prevChatLog) => [
      ...prevChatLog,
      { type: "user", message: inputValue },
    ]);

    sendMessage(inputValue);

    setInputValue("");
  };
const sendMessage = (message: string) => {
  // Synonym map for crops
  const cropSynonymMap: Record<string, string[]> = {
    adaptations: [
      "adaptation",
      "adjustments",
      "changes",
      "modifications",
      "alterations",
    ],
    location_name: [
      "location",
      "place",
      "where",
      "shops",
      "stores",
      "area",
      "region",
    ],
    climate_scores: [
      "climate",
      "weather",
      "conditions",
      "temperature",
      "forecast",
      "drought",
      "precipitation",
      "elevation",
    ],
    crop: ["plants", "seeds", "agriculture", "crops", "harvest"],
    soil_scores: ["soil", "ground", "earth", "terrain", "soil risk", "ph"],
    water_scores: ["water", "rainfall", "erosion"],
  };

  // Synonym map for livestock
  const livestockSynonymMap: Record<string, string[]> = {
    livestock_type: [
      "livestock",
      "animal",
      "animals",
      "cattle",
      "sheep",
      "rear",
    ],
    location_name: ["location", "place", "where", "farm", "pasture", "region"],
    climate_scores: [
      "climate",
      "risk",
      "weather",
      "conditions",
      "temperature",
      "forecast",
      "drought",
      "elevation",
      "precipitation",
      "livestock", "animal", "animals", "cattle", "sheep", "rear", "breed"
    ],
    composite_total_risk:[
      "risk"
    ],
    composite_total_score:[
      "score"
    ],
    profitability_scores:[
      "profitability"
    ],
    water_scores:[
      "water",
      "ground",
    ],
    adaptations: [
      "adaptation",
      "adjustments",
      "changes",
      "modifications",
      "alterations",
    ],
  };

  const matchQueryToKey = (
    query: string,
    obj: Record<string, any>,
    synonymMap: Record<string, string[]>
  ) => {
    const normalizedQuery = query.toLowerCase();
    for (const [key, synonyms] of Object.entries(synonymMap)) {
      if (
        synonyms.some((syn) => normalizedQuery.includes(syn)) ||
        normalizedQuery.includes(key.toLowerCase())
      ) {
        return key;
      }
    }
    const directMatch = Object.keys(obj).find((key) =>
      normalizedQuery.includes(key.toLowerCase())
    );
    return directMatch || null;
  };

  const extractRelevantData = (
    query: string,
    obj: Record<string, any>,
    synonymMap: Record<string, string[]>
  ) => {
    const matchedKey = matchQueryToKey(query, obj, synonymMap);
    if (matchedKey && matchedKey in obj) {
      const value = obj[matchedKey];
      const formattedValue =
        typeof value === "object"
          ? JSON.stringify(value, null, 2)
          : Array.isArray(value)
          ? value.join(", ")
          : value.toString();
      return { keyword: matchedKey, context: formattedValue };
    }
    return null;
  };

const checkForCropsAndLocation = (
  query: string,
  obj: Record<string, any>
) => {
  const normalizedQuery = query.toLowerCase();

  // Ensure the location exists in the provided data
  if (obj.location_name) {
    return `This query is related to livestock. Location found: ${obj.location_name}.`;
  }

  // If no location is found, indicate it's required
  return `This query seems to be related to livestock, but no location was provided. Please include a location to proceed.`;
};

const checkForLivestockAndLocation = (
  query: string,
  obj: Record<string, any>
) => {
  const normalizedQuery = query.toLowerCase();

  // Ensure the location exists in the provided data
  if (obj.location_name) {
    return `This query is related to livestock. Location found: ${obj.location_name}.`;
  }

  // If no location is found, indicate it's required
  return `This query seems to be related to livestock, but no location was provided. Please include a location to proceed.`;
};


  // Check riskData for crops
  if (riskData) {
    const extracted = extractRelevantData(message, riskData, cropSynonymMap);
    const locationContext = checkForCropsAndLocation(message, riskData);
    if (locationContext) {
      callOpenAI(
        `User asked: "${message}". ${locationContext}. Provide a detailed response using this information.`
      );
      return;
    }
    if (extracted) {
      const { keyword, context } = extracted;
      callOpenAI(
        `User asked: "${message}". Relevant data found: '${keyword}' with value: ${context}. Use this to provide a detailed response.`
      );
      return;
    }
  }

  // Check profitData for livestock
  if (profitData) {
    const extracted = extractRelevantData(
      message,
      profitData.total_scores,
      livestockSynonymMap
    );
    const locationContext = checkForLivestockAndLocation(
      message,
      profitData.total_scores
    );
    if (locationContext) {
      callOpenAILivestock(
        `User asked: "${message}". ${locationContext}. Provide a detailed response using this information.`
      );
      return;
    }
    if (extracted) {
      const { keyword, context } = extracted;
      callOpenAILivestock(
        `User asked: "${message}". Relevant data found: '${keyword}' with value: ${context}. Use this to provide a detailed response.`
      );
      return;
    }
  }

  // Fallback if no relevant data is found
  callOpenAILivestock(message);
};




/**
 * Call OpenAI API to process the response.
 */
const callOpenAI = (message: string) => {
  const URL = "/v1/openai/chat";
  const data = {
    messages: [
      {
        role: "user",
        content: message,
      },
    ],
  };

  setIsLoading(true);

  axiosPrivateOid<ChatCompletion>({
    method: "POST",
    url: URL,
    data: data,
  })
    .then((response) => {
      console.log("Request Data:", data);
      console.log("OpenAI Response:", response);

      setChatLog((prevChatLog) => [
        ...prevChatLog,
        {
          type: "bot",
          message:
            // response?.data?.choices?.[0]?.message?.content ||
            response?.data ||
            "I'm sorry, I didn't understand that.",
        },
      ]);

      setIsLoading(false);
    })
    .catch((error) => {
      console.error("OpenAI API error:", error);

      setChatLog((prevChatLog) => [
        ...prevChatLog,
        {
          type: "bot",
          message:
            "Sorry, I couldn't process your request. Please try again later.",
        },
      ]);

      setIsLoading(false);
    });
};
const callOpenAILivestock = (message: string) => {
  const URL = "/v1/openai/chat";
  const data = {
    messages: [
      {
        role: "user",
        content: message,
      },
    ],
  };

  setIsLoading(true);

  axiosPrivateOid<ChatCompletion>({
    method: "POST",
    url: URL,
    data: data,
  })
    .then((response) => {
      console.log("Request Data:", data);
      console.log("OpenAI Response:", response);

      setChatLog((prevChatLog) => [
        ...prevChatLog,
        {
          type: "bot",
          message:
            // response?.data?.choices?.[0]?.message?.content ||
            response?.data || "I'm sorry, I didn't understand that.",
        },
      ]);

      setIsLoading(false);
    })
    .catch((error) => {
      console.error("OpenAI API error:", error);

      setChatLog((prevChatLog) => [
        ...prevChatLog,
        {
          type: "bot",
          message:
            "Sorry, I couldn't process your request. Please try again later.",
        },
      ]);

      setIsLoading(false);
    });
};

  return (
    <>
      {/* component */}
      <div>
        <button
          id="chatbot"
          className={` w-14	 inline-flex items-center justify-center text-sm font-medium border  rounded-full bg-green-600 hover:bg-gray-700 m-0 cursor-pointer border-gray-200 bg-none p-0 normal-case leading-5 hover:text-gray-900 ${
            isChatOpen ? "chat-open" : "chat-closed"
          }`}
          type="button"
          aria-haspopup="dialog"
          aria-expanded={isChatOpen}
          onClick={toggleChat}
        >
          {/* <svg
            xmlns=" http://www.w3.org/2000/svg"
            width={30}
            height={40}
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
            className="text-white block border-gray-200 align-middle"
          >
            <path
              d="m3 21 1.9-5.7a8.5 8.5 0 1 1 3.8 3.8z"
              className="border-gray-200"
            ></path>
          </svg> */}
          <img
            src="/img/BotLogo.png"
            alt=""
            className="object-cover	overflow-hidden	rounded-full"
          />
        </button>
        {isChatOpen && (
          <div
            id="hs-chatbot-container"
            className={`fixed bottom-[calc(4rem+1.5rem)] right-0 mr-4 bg-white  rounded-lg border border-[#e5e7eb] w-[80%] md:w-[440px] h-[560px] z-50 ${
              isChatOpen ? "chat-open" : "chat-closed"
            }`}
          >
            {/* Heading */}
            <div className="flex justify-between items-center space-y-1.5 p-6 bg-slate-700  border-b">
              <div>
                <h2 className="font-semibold text-white text-lg tracking-tight">
                  AgriBot
                </h2>
                <p className="text-sm text-gray-300 leading-3 mt-2">
                  {t("Your climate-smart agriculture guide")}
                </p>
              </div>
              <button
                type="button"
                onClick={toggleChat}
                className="hs-dropdown-toggle inline-flex flex-shrink-0 justify-center items-center h-8 w-8 rounded-md text-white hover:text-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all  dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
                data-hs-overlay="#hs-focus-management-modal"
              >
                <span className="sr-only">Close</span>
                <i className="bi bi-x-lg text-xl"></i>
              </button>
            </div>
            <div id="hs-message-container" className="px-6 pb-6">
              {/* Chat Container */}
              <div
                id="chat-container"
                className="pr-4 h-[400px]"
                style={{
                  minWidth: "100%",
                  display: "table",
                  overflowY: "scroll",
                }}
              >
                <div className="flex gap-3 my-4 text-gray-600 text-sm flex-1">
                  <span className="flex-shrink-0 inline-flex items-center justify-center h-[2.375rem] w-[2.375rem] rounded-full bg-blue-600">
                    <span className="text-sm font-medium text-white leading-none">
                      <img
                        src="/img/BotLogo.png"
                        alt=""
                        className="object-cover	overflow-hidden	rounded-full"
                      />
                    </span>
                  </span>

                  <p className="leading-relaxed">
                    <span className="block font-bold text-gray-700">
                      AgriBot{" "}
                    </span>
                    <p className="text-sm">
                      {t("Welcome to Adapta CS. How can we help you?")}
                    </p>
                  </p>
                </div>
                {chatLog.map((message, index) => (
                  <ChatLogItem
                    key={index}
                    type={message.type}
                    message={message.message}
                  />
                ))}
                {isLoading && (
                  <div key={chatLog.length} className="flex justify-start">
                    <div className="bg-gray-200 rounded-lg p-4 text-white max-w-sm">
                      <TypingAnimation />
                    </div>
                  </div>
                )}
              </div>
              {/* Input box  */}
              <div className="flex items-center pt-0">
                <form
                  className="flex items-center justify-center w-full space-x-2"
                  onSubmit={handleSubmit}
                >
                  <input
                    className="flex h-10 w-full rounded-md border border-[#e5e7eb] px-3 py-2 text-sm placeholder-[#6b7280] focus:outline-none focus:ring-2 focus:ring-[#9ca3af] disabled:cursor-not-allowed disabled:opacity-50 text-[#030712] focus-visible:ring-offset-2"
                    placeholder={t("Type your message")}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                  />
                  <button
                    className="inline-flex items-center justify-center rounded-md text-sm font-medium text-[#f9fafb] disabled:pointer-events-none disabled:opacity-50 bg-slate-700 hover:bg-[#111827E6] h-10 px-4 py-2"
                    type="submit"
                  >
                    <i className="bi bi-send"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ChatBot;
