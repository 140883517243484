import { useState, useEffect } from "react";
import ThemeChanger from "./DarkSwitch";
import { Link, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { Button } from "react-bootstrap";
import { b2cPolicies } from "../authConfig";
import { useUser } from "../context/UserProvider";
import ChatBot from "./Chatbot";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";
import { Coins } from "lucide-react";
import { usePayment } from "../context/Payment";
// import { Coins } from "lucide-react";

type NavbarProp = {
  isLanding?: boolean;
};

const Navbar = ({ isLanding }: NavbarProp) => {
  const [activeLink, setActiveLink] = useState("/dashboard");
  const { searchType } = useUser();
  //@ts-ignore
  const { credits } = usePayment();
  const { t, i18n } = useTranslation("home");
  // const [role, setRole] = useState(localStorage.getItem("empType"));
  // useEffect(() => {
  //   setRole(localStorage.getItem("empType"));
  // }, [localStorage.getItem("empType")]);
  const location = useLocation();
  const { instance, inProgress } = useMsal();

  const handleLanguageChange = (lang: any) => {
    // setLanguage(lang);
    i18n?.changeLanguage(lang);
  };

  const handleLogoutPopup = () => {
    sessionStorage.removeItem("msal.interaction.status"),
      // instance.logoutPopup({
      //   mainWindowRedirectUri: "/login", // redirects the top level app after logout
      // });
      instance.logoutRedirect();
  };

  const handleProfileEdit = () => {
    if (inProgress === InteractionStatus.None) {
      // @ts-ignore
      instance.acquireTokenRedirect(b2cPolicies.authorities.editProfile);
    }
  };

  const handleResetPassword = () => {
    if (inProgress === InteractionStatus.None) {
      // @ts-ignore
      instance.acquireTokenRedirect(b2cPolicies.authorities.resetPassword);
    }
  };

  const handleLinkClick = (link: string) => {
    setActiveLink(link);
  };

  const getLinkClass = (link: string) => {
    return `font-medium ${
      activeLink === link
        ? "text-blue-600 lg:py-6 dark:text-white"
        : "text-gray-800"
    } hover:text-gray-500 lg:py-6 dark:text-gray-500 dark:hover:text-white`;
  };

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  // const signOut = async () => {
  //   // await logout();
  //   localStorage.removeItem("accesstoken");
  //   localStorage.removeItem("email");
  //   localStorage.removeItem("user_data");
  //   localStorage.removeItem("userId");
  //   navigate("/login");
  // };
  const role = localStorage.getItem("empType");
  const userNameEmail = localStorage.getItem("mail");

  return (
    <>
      {/* ========== HEADER ========== */}
      <header className="flex flex-wrap sm:justify-start  sm:flex-nowrap w-full bg-white border-b border-gray-200 text-sm py-3 sm:py-0 dark:bg-gray-800 dark:border-gray-700">
        <nav
          className="relative lg:py-0 py-5  max-w-screen-2xl w-full mx-auto px-4 lg:flex lg:items-center lg:justify-between lg:px-6 xl:px-8"
          aria-label="Global"
        >
          <div className="flex items-center justify-between">
            <Link
              className="flex-none text-xl font-semibold dark:text-white"
              to="#"
              aria-label="Brand"
            >
              <Link to="/landing">
                <img
                  alt="Bitpulse"
                  className="w-32 mx-auto"
                  src="/img/logo.png"
                />
              </Link>
            </Link>
            <div className="lg:hidden">
              <button
                type="button"
                className="hs-collapse-toggle p-2 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-white text-gray-700 shadow-sm align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm dark:bg-slate-900 dark:hover:bg-slate-800 dark:border-gray-700 dark:text-gray-500 dark:hover:text-white dark:focus:ring-offset-gray-800"
                data-hs-collapse="#navbar-collapse-with-animation"
                aria-controls="navbar-collapse-with-animation"
                aria-label="Toggle navigation"
              >
                <svg
                  className="hs-collapse-open:hidden w-4 h-4"
                  width={16}
                  height={16}
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                  />
                </svg>
                <svg
                  className="hs-collapse-open:block hidden w-4 h-4"
                  width={16}
                  height={16}
                  fill="currentColor"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </button>
            </div>
          </div>
          <div
            id="navbar-collapse-with-animation"
            className="hs-collapse hidden overflow-visible transition-all duration-300 basis-full grow lg:block"
          >
            <div className="flex gap-y-4 gap-x-[100px] lg:py-0 py-5  mt-5 sm:flex-row sm:items-center lg:justify-end sm:gap-y-0 sm:gap-x-7 sm:mt-0 sm:pl-7">
              <div className="flex flex-col gap-y-4 mt-5 lg:flex-row lg:items-center lg:justify-end lg:gap-y-0 lg:gap-x-7 lg:mt-0 lg:pl-7 justify-start">
                {isLanding ? (
                  <div className="flex space-x-7">
                    <Link
                      className={getLinkClass("/dashboard")}
                      to={
                        searchType === "livestock" ? "/livestock" : "/dashboard"
                      }
                      onClick={() => handleLinkClick("/dashboard")}
                    >
                      {t("Dashboard")}
                    </Link>
                    <Link
                      className={getLinkClass("/landing")}
                      to="/landing"
                      onClick={() => handleLinkClick("/landing")}
                    >
                      {t("Return to Main")}
                    </Link>
                  </div>
                ) : (
                  <></>
                )}
                <Link
                  className={getLinkClass("/engagement")}
                  to="/engagement"
                  onClick={() => handleLinkClick("/engagement")}
                >
                  {t("Score History")}
                </Link>
                {/* <div className="hs-dropdown [--strategy:static] sm:[--strategy:fixed] [--adaptive:none] sm:[--trigger:hover] sm:py-4">
                <button
                  type="button"
                  className="flex items-center w-full text-gray-800 hover:text-gray-500 font-medium dark:text-gray-500 dark:hover:text-gray-800 "
                >
                  Resources
                  <svg
                    className="ml-2 w-2.5 h-2.5 text-gray-600"
                    width={16}
                    height={16}
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                    />
                  </svg>
                </button>
                <div className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] sm:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 sm:w-48 hidden z-10 bg-white sm:shadow-md rounded-lg p-2 dark:bg-gray-800 sm:dark:border dark:border-gray-700 dark:divide-gray-700 before:absolute top-full sm:border before:-top-5 before:left-0 before:w-full before:h-5">
                  <Link
                    className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                    to="/resources"
                    onClick={() => handleLinkClick("/resources")}
                  >
                    Crops
                  </Link>
                  <Link
                    className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                    to="/scores"
                    onClick={() => handleLinkClick("/scores")}
                  >
                    Scores
                  </Link>
                </div>
              </div> */}
                <div className="relative">
                  <div className="hs-dropdown [--strategy:static] sm:[--strategy:fixed] [--adaptive:none] sm:[--trigger:hover] lg:py-4">
                    <button
                      type="button"
                      className="flex items-center w-full text-gray-800 hover:text-gray-500 font-medium dark:text-gray-500 dark:hover:text-white"
                    >
                      {t("Resources")}
                      <svg
                        className="ml-2 w-2.5 h-2.5 text-gray-600"
                        width={16}
                        height={16}
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                        />
                      </svg>
                    </button>
                    <div className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] sm:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 sm:w-48 hidden z-10 bg-white sm:shadow-md rounded-lg p-2 dark:bg-gray-800 sm:dark:border dark:border-gray-700 dark:divide-gray-700 before:absolute top-full sm:border before:-top-5 before:left-0 before:w-full before:h-5">
                      <div className="relative group">
                        <p className="flex cursor-pointer items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-gray-300">
                          {t("Crops")}
                        </p>
                        <div className="absolute top-0 left-full mt-0 w-48 hidden group-hover:block">
                          <div className="bg-white shadow-md rounded-lg p-2">
                            <Link
                              className="block rounded-md py-1 px-2 text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-gray-300 "
                              to="/scores"
                              onClick={() => handleLinkClick("/scores")}
                            >
                              {t("Score Description")}
                            </Link>
                            <Link
                              className="block py-1 px-2 text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-gray-300 rounded-md p-[8px 12px]"
                              to="/resources"
                              onClick={() => handleLinkClick("/resources")}
                            >
                              {t("Crop Description")}
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="relative group">
                        <p className="flex cursor-pointer items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-gray-300">
                          {t("Livestock")}
                        </p>
                        <div className="absolute top-0 left-full mt-0 w-48 hidden group-hover:block">
                          <div className="bg-white shadow-md rounded-lg p-2">
                            <Link
                              className="block py-1 px-2 text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-gray-300 rounded-md p-[6px]"
                              to="/livestockScores"
                            >
                              {t("Score Description")}
                            </Link>
                            <Link
                              className="block py-1 px-2 text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-gray-300 rounded-md"
                              to="/livestockResources"
                              // onClick={() =>
                              //   handleLinkClick("/livestockResources")
                              // }
                            >
                              {t("Livestock Description")}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {role === "developer" ||
                role === "super_admin" ||
                role === "company_admin" ? (
                  <div className="hs-dropdown [--strategy:static] sm:[--strategy:fixed] [--adaptive:none] sm:[--trigger:hover] lg:py-4">
                    <button
                      type="button"
                      className="flex items-center w-full text-gray-800 hover:text-gray-500 font-medium dark:text-gray-500 dark:hover:text-gray-800 "
                    >
                      {t("Company")}
                      <svg
                        className="ml-2 w-2.5 h-2.5 text-gray-600"
                        width={16}
                        height={16}
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                        />
                      </svg>
                    </button>
                    <div className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] sm:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 sm:w-48 hidden z-10 bg-white sm:shadow-md rounded-lg p-2 dark:bg-gray-800 sm:dark:border dark:border-gray-700 dark:divide-gray-700 before:absolute top-full sm:border before:-top-5 before:left-0 before:w-full before:h-5">
                      <Link
                        className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                        to="/companies"
                      >
                        {t("Companies")}
                      </Link>
                      <Link
                        className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                        to="/users"
                      >
                        {t("Users")}
                      </Link>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {role === "quality_assurance" ||
                role === "developer" ||
                role === "super_admin" ? (
                  <div className="hs-dropdown [--strategy:static] sm:[--strategy:fixed] [--adaptive:none] sm:[--trigger:hover] lg:py-4">
                    <button
                      type="button"
                      className="flex items-center w-full text-gray-800 hover:text-gray-500 font-medium dark:text-gray-500 dark:hover:text-gray-800 "
                    >
                      {t("Crops & Adaptations")}
                      <svg
                        className="ml-2 w-2.5 h-2.5 text-gray-600"
                        width={16}
                        height={16}
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M2 5L8.16086 10.6869C8.35239 10.8637 8.64761 10.8637 8.83914 10.6869L15 5"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                        />
                      </svg>
                    </button>
                    <div className="hs-dropdown-menu transition-[opacity,margin] duration-[0.1ms] sm:duration-[150ms] hs-dropdown-open:opacity-100 opacity-0 sm:w-48 hidden z-10 bg-white sm:shadow-md rounded-lg p-2 dark:bg-gray-800 sm:dark:border dark:border-gray-700 dark:divide-gray-700 before:absolute top-full sm:border before:-top-5 before:left-0 before:w-full before:h-5">
                      <Link
                        className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                        to="/ecocrop"
                      >
                        {t("Eco Crops")}
                      </Link>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <Link
                  className={getLinkClass("/help")}
                  to="/help"
                  onClick={() => handleLinkClick("/help")}
                >
                  {t("Help")}
                </Link>

                <div className="absolute top-[30%] right-3 lg:relative  ">
                  <div className="flex flex-col gap-y-3 items-center  lg:flex lg:flex-row lg:gap-4 ">
                    <div className="">
                      <LanguageSwitcher
                        onLanguageChange={handleLanguageChange}
                      />
                    </div>
                    <ThemeChanger />

                    <div className="hs-dropdown relative inline-flex lg:mr-5 [--placement:bottom-right]">
                      <button
                        id="hs-dropdown-with-header"
                        type="button"
                        className="hs-dropdown-toggle inline-flex flex-shrink-0 justify-center items-center gap-2 h-[2.375rem] w-[2.375rem] rounded-full font-medium bg-white text-gray-700 align-middle hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-offset-2 focus:ring-offset-white transition-all text-xs dark:bg-gray-800 dark:hover:bg-slate-800 dark:text-gray-500 dark:hover:text-white dark:focus:ring-gray-700 dark:focus:ring-offset-gray-800"
                      >
                        <i className="bi bi-person-circle text-2xl text-blue-800"></i>
                      </button>
                      <div
                        className="hs-dropdown-menu transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden min-w-[15rem] bg-white shadow-md rounded-lg p-2 dark:bg-gray-800 dark:border dark:border-gray-700 z-10 "
                        aria-labelledby="hs-dropdown-with-header"
                      >
                        <div className="py-3 px-5 -m-2 bg-gray-100 rounded-t-lg dark:bg-gray-700">
                          <p className="text-sm text-gray-800 dark:text-gray-500">
                            {t("Signed in as")}
                          </p>
                          <p className="text-sm font-medium text-gray-800 dark:text-gray-300">
                            {userNameEmail}
                          </p>
                        </div>
                        <div className="mt-2 py-2 first:pt-0 last:pb-0">
                          {/* <Link
                      className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                      to="/profile"
                    >
                      <i className="bi bi-person text-2xl"></i>
                      Profile
                    </Link> */}
                          <Button
                            className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                            variant="info"
                            onClick={handleProfileEdit}
                          >
                            <i className="bi bi-person text-2xl"></i>
                            {t("Edit Profile")}
                          </Button>
                          <Button
                            className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                            variant="info"
                            onClick={handleResetPassword}
                          >
                            <i className="bi bi-key text-2xl"></i>
                            {t("Reset Password")}
                          </Button>
                          <Link
                            className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                            to="/engagement"
                          >
                            <i className="bi bi-hourglass-split text-2xl"></i>
                            {t("History")}
                          </Link>
                          <button
                            onClick={handleLogoutPopup}
                            className="flex items-center gap-x-3.5 py-2 px-3 rounded-md text-sm text-gray-800 hover:bg-gray-100 focus:ring-2 focus:ring-blue-500 dark:text-gray-500 dark:hover:bg-gray-700 dark:hover:text-gray-300"
                          >
                            <i className="bi bi-door-open text-2xl"></i>
                            {t("Sign Out")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {role === "single_user" ? (
                <>
                  <Link
                    to="/currency"
                    className="flex items-center space-x-2 bg-blue-200 px-3 py-2 rounded-full hover:bg-blue-300 transition-colors"
                  >
                    <Coins className="text-yellow-400" size={20} />
                    <span className="font-semibold">{credits}</span>
                  </Link>
                </>
              ) : (
                <></>
              )}

              <div className="chatbot-m">
                <ChatBot />
              </div>
            </div>
          </div>
        </nav>
        <div className="chatbot-d">
          <ChatBot />
        </div>
      </header>

      {/* ========== END HEADER ========== */}
    </>
  );
};

export default Navbar;
